.color-dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 10px;
  border-radius: 50%;
}

.borderedBox {
  height: 300px;
  width: 100%;
  /* border: 1px dashed gray; */
  /* border-radius: 5px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.previewImage {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.imagePlaceholder {
  margin: 0;
}

 .product-label-select > div {
  display: flex !important;
 }

 .image-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
 }

 .css-ob3on5-MuiAutocomplete-root .MuiAutocomplete-tag {
  background-color: rgb(236, 100, 60) !important;
 }