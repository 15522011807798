.preview-image {
    width: 100%;
    height: 100%;
}

.smooth-image {
    transition: opacity 1s;
 }

.image-visible {
    opacity: 1;
}

.image-hidden {
    opacity: 0;
}
  
@keyframes preloader-block {
    from {
      background-position: 0%, 0;
    }
    to {
      background-position: 170%, 0;
    }
}
  
.smooth-preloader {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(245, 245, 245, 0.34);
    .loader {
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to right, #e6e6e6, #ffffff, #e6e6e6);
      display: inline-block;
      background-size: 200%;
      animation: preloader-block 2s linear 0s infinite reverse forwards;
    }
}