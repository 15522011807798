.checkbox-form-label {
    font-size: 0.875rem !important;
    margin-right: 10px;
}

.checkbox-form-color {
    color: #495057 !important;
}

.active-profile-label {
    font-weight: 500;
    color: #495057;
}

.checkbox-label {
    font-weight: 400;
    color: #495057;
}

.input-post-fix-label > p {
    font-size: 0.875rem !important;
    color: #495057;
}

.week-day-label {
    font-size: 1.2rem;    
}

.MuiDivider-root {
    margin-top: 0.3rem !important;
}

/* .MuiFormLabel-root .Mui-focused {
    color: #495057 !important
} */